import React from 'react'
import PropTypes from 'prop-types'
import useNavData from '~utils/useNavData'
import DataViewer from '~utils/DataViewer'
import styled from '@emotion/styled'
import Section from './Section'
import { mobile, tablet, UnderlineLink, NestedUnderlineLink } from '~styles/global'
import { Link } from 'gatsby'
import resolveLink from '../utils/resolveLink'
import Newsletter from '~components/Newsletter'
import RichText from '~components/RichText'
import { JNLetters } from './Svg'
import SanityLink from '~components/SanityLink'

const Footer = ({ className }) => {
	const navData = useNavData()
	return(
		<Wrap className={className}>
			<Explore>
				<ExploreLinks>
					<ExploreHeading>{navData.exploreHeading}</ExploreHeading>
					{navData?.exploreMenu?.map((item, i)=> (
						<React.Fragment key={item.text}>
							<ExploreLink link={{
								linkType: 'internal', 
								document: item?.link?.document,
							}}
							className='large'
							>
								<Number className='large' dangerouslySetInnerHTML={{__html: `&#931${i + 2}`}}/>
								<ExploreLinkText>{item.text}</ExploreLinkText>
							</ExploreLink>
						</React.Fragment>
					))}
				</ExploreLinks>
			</Explore>
			<NewsletterSection>
				<NewsletterHeading>{navData.newsletterHeading}</NewsletterHeading>
				<NewsletterForm successMessage={navData.newsletterSuccess} showName/>
			</NewsletterSection>
			<Bottom>
				<LogoContainer>
					<JNLogo/>
				</LogoContainer>
				<SiteDesc>
					<RichText content={navData.siteDescription}/>
				</SiteDesc>
				<Acknowledgement>
					<RichText content={navData.acknowledgement}/>
				</Acknowledgement>
				<Menu>
					{navData.footerMenu.map(item => (
						<MenuLinkWrap key={item.text}><MenuLink className='h3' link={item.link} >{item.text}</MenuLink></MenuLinkWrap>	
					))}
				</Menu>
				<Credits>
					<Credit><span className='numbers'>©</span> Jon Netti, {new Date().getFullYear()}</Credit>
					<Credit>Site Design &amp; Build by<a href="https://afom.com.au/" target="_blank" rel="noreferrer"> AFOM</a></Credit>
				</Credits>
				<BackToTop 
					className='h3'
					onClick={() => window.scrollTo({
						top: 0,
						left: 0,
						behavior: 'smooth'
					})}>
					Back to top
				</BackToTop>
			</Bottom>
			{/* <DataViewer data={navData} name="navData"/> */}
		</Wrap>
	)
}

const Wrap = styled.footer`
	border-top: 1px solid var(--black);
	margin-top: -1px;
`
const Explore = styled.div`
	margin-top: var(--xxxxl);
	border-bottom: 1px solid var(--black);
	${mobile}{
		margin-top: var(--xxxl);
	}
`
const ExploreHeading = styled.h2`
	margin-bottom: var(--xl);
	width: 100%;
`
const Number = styled.span`
	margin-right: var(--xs);
`
const ExploreLinks = styled.div`
	grid-column: 4/10;
	text-align: center;
	max-width: 900px;
	margin: 0 auto;
	padding-left: 40px;
	padding-right: 40px;
	margin-bottom: var(--xxxxl);
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	${mobile}{
		grid-column: span 6;
		padding-right: 20px;
		padding-left: 20px;
		margin-bottom: var(--xxxl);
	}
`
const ExploreLink = styled(NestedUnderlineLink)`
	margin-right: var(--xs);
	white-space: nowrap;
	${mobile}{
		display: block;
	}
`
const ExploreLinkText = styled.div`
	display: inline;
`
const NewsletterSection = styled(Section)`
	border-bottom: 1px solid var(--black);
`
const NewsletterHeading = styled.h2`
	text-align: center;
	grid-column: span 12;
	margin-top: var(--xxxxl);
	margin-bottom: var(--xxxl);
	${mobile}{
		grid-column: 2/6;
		margin-top: var(--xxxl);
		margin-bottom: var(--xxxl);
	}
`
const NewsletterForm = styled(Newsletter)`
	grid-column: 2/12;
	margin-bottom: var(--xxxxl);
	${mobile}{
		grid-column: span 6;
		margin-bottom: var(--xxl);
	}
`
const Bottom = styled(Section)`
	margin-top: var(--xxxl);
	margin-bottom: var(--xxxl);
	text-align: center;
`
const LogoContainer = styled.div`
	grid-column: span 12;
	${mobile}{
		grid-column: span 6;
	}
`
const JNLogo = styled(JNLetters)`
	margin-bottom: var(--xxl);
	width: 78px;
	${mobile}{
		width: 58px;
	}
`
const SiteDesc = styled.h2`
	grid-column: 4/10;
	margin-bottom: var(--xxl);
	${mobile}{
		grid-column: span 6;
	}
`
const Acknowledgement = styled.h2`
	grid-column: 3/11;
	margin-bottom: var(--xxl);
	${mobile}{
		grid-column: span 6;
	}
`
const Menu = styled.div`
	grid-column: span 12;
	display: flex;
	justify-content: center;
	margin-bottom: var(--xxl);
	${tablet}{
		flex-wrap: wrap;
	}
	${mobile}{
		grid-column: span 6;
		display: block;
		columns: 2;
	}
`
const MenuLink = styled(UnderlineLink)`
	display: inline-block;
	white-space: nowrap;
	&:after{
		bottom: 0.1em;
	}
`
const MenuLinkWrap = styled.div`
	display: inline-block;
	margin-right: var(--xl);
	&:last-of-type{
		margin-right: 0px;
	}
	${tablet}{
		margin-bottom: var(--m);
	}
	${mobile}{
		display: block;
		margin-right: 0px;
	}
`
const Credits = styled.div`
	grid-column: span 12;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-bottom: var(--xxl);
	${mobile}{
		grid-column: span 6;
		flex-direction: column;
		margin-bottom: var(--l);
	}
`
const Credit = styled.h3`
	&:first-of-type{
		margin-right: var(--xl);
		${mobile}{
			margin-right: 0px;
			margin-bottom: var(--l);
		}
	}
`
const BackToTop = styled.button`
	grid-column: span 12;
	${mobile}{
		grid-column: span 6;
	}
`

Footer.propTypes = {
	className: PropTypes.string,
}

export default Footer
